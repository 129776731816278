import type { CamelCasedPropertiesDeep } from 'type-fest';

import ApiService from '@zola-helpers/client/dist/es/http/api';
import type {
  WAddCartItemRequest,
  WDeleteCartItemsRequest,
  WCartView,
  WProductView,
  CreatePaymentIntentRequest,
  WPaymentIntentView,
  WGetCartView,
} from '@zola/svc-web-api-ts-client';

export const getProductByLookKey = (
  key: string,
  optionalParams?: { store?: string; business_unit?: string }
) => {
  const queryParams = new URLSearchParams(optionalParams);
  return ApiService.get<WProductView>(
    `/web-registry-api/v1/product/${key}?${queryParams.toString()}`
  );
};

export const createOrUpdatePaymentIntent = (cartId: string) => {
  const paymentIntentBody: CamelCasedPropertiesDeep<Omit<
    CreatePaymentIntentRequest,
    'ip_address' | 'user_agent'
  >> = {
    cartId,
  };

  return ApiService.post<
    CamelCasedPropertiesDeep<WPaymentIntentView>,
    CamelCasedPropertiesDeep<Omit<CreatePaymentIntentRequest, 'ip_address' | 'user_agent'>>
  >('/web-registry-api/v1/checkout/create-payment-intent', paymentIntentBody);
};

export const removeCartItems = (itemIds: string[], store?: string) => {
  const body: Omit<WDeleteCartItemsRequest, 'user_id' | 'ip_address' | 'user_agent' | 'store'> = {
    item_ids: itemIds,
  };
  const query = store ? `?store=${store}` : '';
  return ApiService.post<WGetCartView>(`/web-api/v1/cart/remove-items${query}`, body);
};

export const addCartItem = (
  body: Omit<WAddCartItemRequest, 'user_id' | 'ip_address' | 'user_agent' | 'store'>
) =>
  ApiService.post<WCartView>('/web-api/v1/cart/add-item', body).then(response => {
    const { cart_id } = response;
    if (cart_id) {
      return createOrUpdatePaymentIntent(cart_id);
    }
    return null;
  });

export const getCartByStore = (store: string) =>
  ApiService.get<WGetCartView>(`/web-api/v1/cart?store=${store}`);
